// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { ParallaxBanner, useParallax } from 'react-scroll-parallax'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import List from '../components/list'
import '../components/list/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Magazine',
  slug: 'magazine',
  abstract:
    'Perspectives on people, places, and events that have shaped Auroville',
  breadcrumbs: [
    { title: 'Auroville Archives', slug: '' },
    { title: 'Magazine', slug: '/magazine' },
  ],
  headerAbstrct: true,
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logos/logo.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => {
  const { ref: ref1 } = useParallax({ speed: 20 })
  const { ref: ref2 } = useParallax({ speed: -20 })

  return (
    <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
      <div className="link-bar nmt-md">
        <p>
          <span>8 Items</span>
          <span>Latest article on 1st of May 2022</span>
        </p>
        <p>
          <span>Page #1</span>
          <span>#2</span>
        </p>
      </div>
      <Row
        justify="space-between"
        style={{ paddingTop: '160px', paddingBottom: '160px' }}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} ref={ref1}>
          <div className="list-item">
            <h2>Presenting Auroville Archives</h2>
            <ParallaxBanner
              layers={[
                {
                  image:
                    'https://images.unsplash.com/photo-1577083553085-d63cdcdf2fc8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2796&q=80',
                  speed: -15,
                },
              ]}
              className="img flow"
            />
            <p>
              This is useful to set up any Provider components that will wrap
              your application. For setting persistent UI elements around pages
              use wrapPageElement.
            </p>
          </div>
          <div className="list-item">
            <h2>Open-Access Archive</h2>
            <ParallaxBanner
              layers={[
                {
                  image:
                    'https://images.unsplash.com/photo-1578301978693-85fa9c0320b9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2519&q=80',
                  speed: -15,
                },
              ]}
              className="img flow"
            />
            <p>
              This is useful to set up any Provider components that will wrap
              your application. For setting persistent UI elements around pages
              use wrapPageElement.
            </p>
          </div>
          <div className="list-item">
            <h2>Help us build the archive</h2>
            <ParallaxBanner
              layers={[
                {
                  image:
                    'https://images.unsplash.com/photo-1578926375605-eaf7559b1458?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2726&q=80',
                  speed: -15,
                },
              ]}
              className="img flow"
            />
            <p>
              This is useful to set up any Provider components that will wrap
              your application. For setting persistent UI elements around pages
              use wrapPageElement.
            </p>
          </div>
          <div className="list-item">
            <h2>A Visual Timeline</h2>
            <ParallaxBanner
              layers={[
                {
                  image:
                    'https://images.unsplash.com/photo-1577083287809-1c229a750bba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2944&q=80',
                  speed: -15,
                },
              ]}
              className="img flow"
            />
            <p>
              This is useful to set up any Provider components that will wrap
              your application. For setting persistent UI elements around pages
              use wrapPageElement.
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} ref={ref2}>
          <div className="list-item">
            <h2>Presenting Collections</h2>
            <ParallaxBanner
              layers={[
                {
                  image:
                    'https://images.unsplash.com/photo-1577083862199-97d54558971f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2818&q=80',
                  speed: -15,
                },
              ]}
              className="img flow"
            />
            <p>
              This is useful to set up any Provider components that will wrap
              your application. For setting persistent UI elements around pages
              use wrapPageElement.
            </p>
          </div>
          <div className="list-item">
            <h2>The ARK System</h2>
            <ParallaxBanner
              layers={[
                {
                  image:
                    'https://images.unsplash.com/photo-1578926372062-6ddf6fc55e0e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3312&q=80',
                  speed: -15,
                },
              ]}
              className="img flow"
            />
            <p>
              This is useful to set up any Provider components that will wrap
              your application. For setting persistent UI elements around pages
              use wrapPageElement.
            </p>
          </div>
          <div className="list-item">
            <h2>Auroville Archives and Research into Auroville</h2>
            <ParallaxBanner
              layers={[
                {
                  image:
                    'https://images.unsplash.com/photo-1577083300575-b42d4598b25b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3287&q=80',
                  speed: -15,
                },
              ]}
              className="img flow"
            />
            <p>
              This is useful to set up any Provider components that will wrap
              your application. For setting persistent UI elements around pages
              use wrapPageElement.
            </p>
          </div>
          <div className="list-item">
            <h2>What Will You Contribute?</h2>
            <ParallaxBanner
              layers={[
                {
                  image:
                    'https://images.unsplash.com/photo-1577083862054-7324cd025fa6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3341&q=80',
                  speed: -15,
                },
              ]}
              className="img flow"
            />
            <p>
              This is useful to set up any Provider components that will wrap
              your application. For setting persistent UI elements around pages
              use wrapPageElement.
            </p>
          </div>
        </Col>
      </Row>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
